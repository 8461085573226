import {
	BillingAccountRequest,
	CreditCardInformationRequest,
	EcommerceBillingAccountResponse,
	RequestStateOperation,
} from '@sharefile/ecomm-models/apis/ecomm/index';
import {
	PaywallEvent,
	RecentPaywallEvent,
} from '@sharefile/ecomm-models/entitlements/entitlements';
import { EcommOrder } from '@sharefile/ecomm-models/orders/index';
import {
	PlanBase,
	PlanCatalog,
	PlanInfo,
	QuoteRequest,
	QuoteResponse,
} from '@sharefile/ecomm-models/plans/index';
import { UpdatePlanModalType } from '../EditBilling/UpdatePlan';
import { PlanChangeAllowedResponse, PurchaseOrderInformation } from '../types';
import {
	AccountCancellationRequest,
	AuthorizeRequest,
	AuthorizeResponse,
	PaymentGatewayFPIRequest,
	PaymentGatewayRedirect,
	WorldPayRedirect,
} from './models';
import { createPaywallRequest, createRequest } from './requestBuilder';

export const BillingHttp = {
	//#region plan info
	async getCurrentPlan(accountId: string): Promise<PlanInfo[]> {
		const request = createRequest(`plans/current?accountId=${accountId}`, {
			method: 'GET',
			headers: {
				'content-type': 'application/json',
			},
		});

		const response = await fetchOrThrowErrorFromResponse(request, {
			expectedStatus: 200,
			defaultErrorMessage: 'Unable to get current plan',
		});
		const data: PlanInfo[] = await response.json();
		return data;
	},
	async getCurrentTrialPlan(accountId: string): Promise<PlanBase> {
		const request = createRequest(`plans/current?accountId=${accountId}`, {
			method: 'GET',
			headers: {
				'content-type': 'application/json',
			},
		});

		const response = await fetchOrThrowErrorFromResponse(request, {
			expectedStatus: 200,
			defaultErrorMessage: 'Unable to get current trial plan',
		});
		const data: PlanBase = await response.json();
		return data;
	},
	async getAvailablePlans(productCode: string): Promise<PlanCatalog[]> {
		const request = createRequest(`plans/availability?productCode=${productCode}`, {
			method: 'GET',
			headers: {
				'content-type': 'application/json',
			},
		});
		const response = await fetchOrThrowErrorFromResponse(request, {
			expectedStatus: 200,
			defaultErrorMessage: 'Unable to get available plans',
		});
		const data: PlanCatalog[] = await response.json();
		return data;
	},
	async getPlanCatalog(): Promise<PlanCatalog[]> {
		const request = createRequest(
			'/plans/catalog?productCode=SFPREM001&productCode=SFADV001&productCode=SFIA001&productCode=SFVDR001',
			{
				method: 'GET',
				headers: {
					'content-type': 'application/json',
				},
			}
		);
		const response = await fetchOrThrowErrorFromResponse(request, {
			expectedStatus: 200,
			defaultErrorMessage: 'Unable to get plan catalog',
		});
		const data: PlanCatalog[] = await response.json();
		return data;
	},
	async calculatePlanQuote(quoteRequest: QuoteRequest): Promise<QuoteResponse> {
		const request = createRequest('plans/quote', {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			body: JSON.stringify(quoteRequest),
		});
		const response = await fetchOrThrowErrorFromResponse(request, {
			expectedStatus: 200,
			defaultErrorMessage: 'Unable to calculate plan quote',
		});
		const data: QuoteResponse = await response.json();
		return data;
	},
	async isStateOperationPending(
		accountId: string,
		stateOperation: RequestStateOperation
	): Promise<boolean> {
		const request = createRequest(`request/state/${accountId}`, {
			method: 'POST',
			body: JSON.stringify({ operation: stateOperation }),
		});
		try {
			const response = await fetchOrThrowErrorFromResponse(request, {
				expectedStatus: 200,
				defaultErrorMessage: 'Unable to verify if plan changes are allowed',
			});
			const isOperationAllowed = ((await response.json()) as PlanChangeAllowedResponse)
				.isOperationAllowed;
			if (!isOperationAllowed) {
				return true;
			}
		} catch {}
		return false;
	},
	//#endregion
	//#region paywall events
	async recordPaywallEvent(event: PaywallEvent): Promise<void> {
		const request = createPaywallRequest('api/v1/paywall/events', {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			body: JSON.stringify(event),
		});
		try {
			await fetchOrThrowErrorFromResponse(request, {
				expectedStatus: 200,
				defaultErrorMessage: 'Unable to record paywall event',
			});
		} catch {}
	},
	async getRecentPaywallEvent(
		userRid: string,
		updateType: UpdatePlanModalType
	): Promise<RecentPaywallEvent | null> {
		const request = createPaywallRequest(
			`api/v1/paywall/events?userRID=${userRid}&upgradeType=${updateType}`,
			{
				method: 'GET',
				headers: {
					'content-type': 'application/json',
				},
			}
		);
		try {
			const response = await fetchOrThrowErrorFromResponse(request, {
				expectedStatus: 200,
				defaultErrorMessage: 'Unable to get recent paywall event',
			});
			return response.json();
		} catch {}
		return Promise.resolve(null);
	},
	//#endregion
	//#region orders purchase
	async orderPurchase(ecommOrder: EcommOrder): Promise<Response> {
		const request = createRequest('orders/purchase', {
			method: 'PATCH',
			headers: {
				'content-type': 'application/json',
				correlationId: ecommOrder.correlationId,
			},
			body: JSON.stringify(ecommOrder),
		});
		return await fetchOrThrowErrorFromResponse(request);
	},
	async trialOrderPurchase(trialOrder: PurchaseOrderInformation): Promise<Response> {
		const request = createRequest('orders/purchase', {
			method: 'POST',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify(trialOrder),
		});
		return await fetchOrThrowErrorFromResponse(request);
	},
	//#endregion
	//#region account info handling
	async getAccountBillingInfo(
		accountId: string,
		skipPaymentInfo: boolean
	): Promise<EcommerceBillingAccountResponse> {
		const request = createRequest(
			`billing/account?accountId=${accountId}&skipPaymentInfo=${skipPaymentInfo}`,
			{
				method: 'GET',
				headers: {
					'content-type': 'application/json',
				},
			}
		);
		const response = await fetchOrThrowErrorFromResponse(request, {
			expectedStatus: 200,
			defaultErrorMessage: 'Unable to get account billing info',
		});
		const data: EcommerceBillingAccountResponse = await response.json();
		return data;
	},
	async updateAccountBillingInfo(
		billingAccountRequest: BillingAccountRequest
	): Promise<void> {
		const request = createRequest('billing/account', {
			method: 'PATCH',
			headers: {
				'content-type': 'application/json',
			},
			body: JSON.stringify(billingAccountRequest),
		});
		await fetchOrThrowErrorFromResponse(request, {
			expectedStatus: 204,
			defaultErrorMessage: 'Unable to update account billing info',
		});
		return;
	},
	//#endregion
	//#region Worldpay handling
	async redirectToWorldpayPaymentGateway(
		fpiRequest: PaymentGatewayFPIRequest
	): Promise<void> {
		const request = createRequest('/payment/gateway/fullpageintegration', {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			body: JSON.stringify(fpiRequest),
		});

		const response = await fetchOrThrowErrorFromResponse(request, {
			expectedStatus: 200,
			defaultErrorMessage: 'Unable to update credit card information',
		});

		const worldpayResponse: WorldPayRedirect = await response.json();
		window.location.href = worldpayResponse.redirectUrl;
	},
	//#endregion
	//#region SnapPay handling
	async getPaymentGatewayUrl(fpiRequest: PaymentGatewayFPIRequest): Promise<string> {
		const request = createRequest('payment/gateway', {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			body: JSON.stringify(fpiRequest),
		});

		const response = await fetchOrThrowErrorFromResponse(request, {
			expectedStatus: 200,
			defaultErrorMessage: 'Unable to update credit card information',
		});

		const responseData: PaymentGatewayRedirect = await response.json();
		return responseData.paymentGatewayUrl;
	},

	async authorizeCardInformation(
		requestData: AuthorizeRequest
	): Promise<AuthorizeResponse> {
		const request = createRequest('payment/gateway/authorize', {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			body: JSON.stringify(requestData),
		});

		const response = await fetchOrThrowErrorFromResponse(request, {
			expectedStatus: 200,
			defaultErrorMessage: 'Unable to authorize credit card information.',
		});

		return await response.json();
	},

	async updateCreditCardInformation(
		billingAccountRequest: CreditCardInformationRequest
	): Promise<void> {
		const request = createRequest('creditcard/information', {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			body: JSON.stringify(billingAccountRequest),
		});
		await fetchOrThrowErrorFromResponse(request, {
			defaultErrorMessage: 'Unable to update credit card information',
		});
	},
	//#endregion
	//#region Reactivate and Cancel account
	async cancelAccount(
		accountId: string,
		cancellationRequest: AccountCancellationRequest
	) {
		const request = createRequest(`/accounts/${accountId}`, {
			method: 'DELETE',
			headers: {
				'content-type': 'application/json',
			},
			body: JSON.stringify(cancellationRequest),
		});
		return await fetch(request);
	},
	async initiateSfHybrisAccountReactivation(accountId: string): Promise<Response> {
		const request = createRequest('accounts/reactivation', {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			body: JSON.stringify({
				accountId,
			}),
		});
		const response = await fetchOrThrowErrorFromResponse(request, {
			expectedStatus: 202,
			defaultErrorMessage: 'Unable to initiate reactivation',
		});
		return response;
	},
	//#endregion
};

async function fetchOrThrowErrorFromResponse(
	request: Request,
	config: { expectedStatus?: number; defaultErrorMessage?: string } = {}
) {
	const response = await fetch(request);
	const isError =
		config.expectedStatus !== undefined
			? response.status !== config.expectedStatus
			: response.status > 399;

	if (isError) {
		let errorBody: any = {};
		if (response.headers.get('content-type')?.includes('application/json')) {
			errorBody = await response.json().catch(_ => ({}));
		}
		const message =
			errorBody?.message ??
			errorBody?.Message ??
			errorBody?.detail ??
			config.defaultErrorMessage ??
			'Unknown error';
		throw new BillingNetworkError(message, response, errorBody?.statusCode, errorBody);
	}
	return response;
}

export class BillingNetworkError extends Error {
	public constructor(
		public message: string,
		public response: Response,
		public status?: number,
		public body?: any
	) {
		super(message);
		this.status = status ?? response.status;
	}
}
