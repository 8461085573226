import { ModalStyles } from '@sharefiledev/sharefile-appshell';
import { Breakpoint, ModalProps } from 'antd';
/**
 * Returns the default modal props based on screen sizes if supplied
 */
export const getDefaultModalProps: (
	screens?: Partial<Record<Breakpoint, boolean>>,
	isMinimizedIframeEnabled?: boolean
) => Partial<ModalProps> = (screens, isMinimizedIframeEnabled) => {
	// When feature flag is removed, can remove any undefined properties
	if (isMinimizedIframeEnabled) {
		return {
			centered: true,
			width: screens?.sm ? '444px' : '100vw', // 444px is the minimum width of a modal per design standards
			height: 'auto',
			maskClosable: false,
			footer: null,
			styles: {
				body: {
					height: screens?.sm ? undefined : 'calc(100vh - 56px)',
					display: 'flex',
					flexDirection: 'column',
					gap: '8px',
				} as ModalStyles['body'],
			},
		};
	}

	return {
		centered: true,
		width: screens?.sm ? '579px' : '100vw',
		footer: null,
		maskClosable: false,
		styles: {
			body: {
				height: screens?.sm ? '660px' : 'calc(100vh - 56px)', // third screen experience
				display: 'flex',
				flexDirection: 'column',
				gap: '8px',
			} as ModalStyles['body'],
		},
	};
};
